import Head from 'next/head'
import { useEffect, useId, useRef, useState } from 'react'
let Square = import('@square/web-sdk')
import { v4 } from 'uuid';
import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router'
import { toast } from 'react-toastify';
import * as Sentry from "@sentry/browser";

export default function Home(props ) {
  let id = v4();
  let { amount, currency, invoiceNo } = props;
  const [customer, setCustomer] = useState<string>();
  const [shop, setShop] = useState<string>();
  const router = useRouter()
  const [loading, setLoading] = useState<boolean>(false);
  let card = useRef<any>();
  let payments = useRef<any>();

  async function handlePay(event){
    event.preventDefault();
    if(!card.current || !payments.current) return;

    try {
      setLoading(true);
      const result = await card.current.tokenize();

      const verificationDetails = await payments.current.verifyBuyer(result.token, {
        amount: amount,
        billingContact: {},
        currencyCode: 'GBP',
        intent: 'CHARGE'
      });

      let response = await fetch(process.env.NEXT_PUBLIC_HOST+'/api/pay', {
        method: 'POST',
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ amount, token: result.token, id: id, invoiceNo, verificationToken: verificationDetails.token, customer, shop })
      });

      if(response.status == 200){
        toast.success('Payment successful 💸')
        setLoading(false);
      }else{
        toast.error('Payment unsuccessful, please try again later ⏳')
        setTimeout(() => router.reload(), 5000)
      }


    } catch (e) {
      Sentry.captureException(e);
      toast.error('Payment unsuccessful, please try again later ⏳')
      setTimeout(() => router.reload(), 5000)
    }
  }

  async function main() {
    //@ts-ignore
    Square = await Square;
    //@ts-ignore
    payments.current = await Square.payments(process.env.NEXT_PUBLIC_APPID, process.env.NEXT_PUBLIC_LOCATIONID);
    card.current = await payments.current.card();
    await card.current.attach('#card-container');
  }

  useEffect(() => {
    if(!amount || !currency || !invoiceNo){
      router.push("/error")
    }else{
      main()
    }
  }, []);

  return (
    <div className="flex bg-pink-100 h-screen items-center justify-center">
      <Head>
        <title>JJ Payment Page</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <main className="p-12 text-center shadow-lg w-7/12 mx-auto bg-white rounded">
        <div>
          <img src="/hummingbird.png" className="w-16 mx-auto mb-8" />
          <h1 className='text-2xl font-semibold mb-10 text-gray-800'>Payment for invoice: {invoiceNo} for £{amount}</h1>
        </div>
        <div>
          <input className='bg-gray-100 rounded shadow mb-6 w-full p-4 focus:outline-none font-semibold text-gray-800' type="text" placeholder='Shop name' value={shop} onChange={(e) => setShop(e.target.value)} />
          <input className='bg-gray-100 rounded shadow mb-6 w-full p-4 focus:outline-none font-semibold text-gray-800' type="text" placeholder='Cardholder name' value={customer} onChange={(e) => setCustomer(e.target.value)} />
        </div>
        <div id="card-container">
        </div>
        <button disabled={loading} onClick={handlePay} id="card-button" className='bg-green-500 text-white px-8 py-4 rounded-full font-bold mt-4' type="button">
          {!loading && (<span>Pay</span>)}
          {loading && (
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          )}
        </button>
      </main>
    </div>
  )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  return {
    props: {
      amount: context.query.amount ? context.query.amount : null,
      invoiceNo: context.query.invoiceNo ? context.query.invoiceNo : null,
      currency: context.query.currency ? context.query.currency : null
    }
  }
}
